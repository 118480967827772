.column-orange {
  background: linear-gradient(
      0deg,
      var(--Colors-Base-Orange-Orange30, rgba(255, 170, 0, 0.3)) 0%,
      var(--Colors-Base-Orange-Orange30, rgba(255, 170, 0, 0.3)) 100%
    ),
    var(--Colors-Netural-Background-colorBG_primary, #fff);
}

.column-green {
  background: linear-gradient(
      0deg,
      rgba(72, 199, 65, 0.3) 0%,
      rgba(72, 199, 65, 0.3) 100%
    ),
    var(--Colors-Netural-Background-colorBG_primary, #fff);
}

.column-blue {
  background: linear-gradient(
      0deg,
      rgba(67, 138, 251, 0.2) 0%,
      rgba(67, 138, 251, 0.2) 100%
    ),
    #fff;
}

.custom-bordered-table {
  border-radius: 8px;
  td {
    border-top: 1px solid rgba(50, 60, 71, 0.2) !important;
    border-left: 1px solid rgba(50, 60, 71, 0.2) !important;
    border-right: none;
    border-bottom: none;
    &:last-child {
      border-top: none;
      border-left: none;
      border-bottom: none;
      border-right: 1px solid rgba(50, 60, 71, 0.2) !important;
    }
  }
  th {
    border-top: 1px solid rgba(50, 60, 71, 0.2) !important;
    border-left: 1px solid rgba(50, 60, 71, 0.2) !important;
    border-right: none;
    border-bottom: none;
  }
  tbody {
    tr {
      &:last-child {
        td {
          border-bottom: 1px solid rgba(50, 60, 71, 0.2) !important;
        }
      }
    }
  }
}
