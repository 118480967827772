@import "global/sass-overrides";
@import "global/clarity";
@import "@cds/core/global.min.css";
@import "@clr/icons/clr-icons.min.css";
@import "@ng-select/ng-select/themes/default.theme.css";
@import "@angular/cdk/overlay-prebuilt.css";
@import "global/buttons";
@import "global/table";
@import "global/forms";
@import "global/overrides";
@import "global/utilities";
@import "fonts";
@import "global/global";

@import "component/prosemirror";

@import "theme/default";
@import "theme/dark";

@import "rtl";

@tailwind base;
@tailwind components;
@tailwind utilities;
